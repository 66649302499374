













































































import {
  Component, Inject, Vue, Watch,
} from 'vue-property-decorator';

import Card from '@/components/material/Card.vue';
import { SnackbarOptions } from '@/models/form';
import EcSnackBar from 'common-components/src/components/form/ec-snack-bar.vue';
import ServiceHookEditor from '@/components/service-hook-maintenance/service-hook-editor.vue';
import ServiceHookService from '@/services/service-hook-service';
import { ServiceHook, ServiceHookUpdateModel } from '@/models/service-hook-maintenance.d';

import { getModule } from 'vuex-module-decorators';
import AppState from '@/store/modules/app-module';
import { backOr } from '@/router/index';
import { ApiError } from '@/models/hal.d';
import ServiceHookTest from '@/components/service-hook-maintenance/service-hook-test.vue';

const appState = getModule(AppState);

@Component({
  components: {
    Card,
    ServiceHookEditor,
    EcSnackBar,
    ServiceHookTest
  },
})
export default class ServiceHookView extends Vue {
  @Inject() ServiceHookService!: ServiceHookService

  serviceHookId?: string;

  serviceHook: ServiceHook = {} as ServiceHook;
  originalServiceHook: ServiceHook = {} as ServiceHook;
  serviceHookName?: string;

  apiErrors: ApiError[] = [];

  loading = false;

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  editing?: boolean;

  showTestDialog = false;

  @Watch('$route', { immediate: true })
  onRouteChanged(value: any): Promise<void> {
    this.serviceHookId = value.params.id;

    if (!this.serviceHookId) {
      this.editing = false;
      return Promise.resolve();
    }
    this.editing = true;

    this.loading = true;
    return this.ServiceHookService
      .readSingle(this.serviceHookId)
      .then((serviceHook) => {
        this.originalServiceHook = JSON.parse(JSON.stringify(serviceHook)) as ServiceHook;
        this.serviceHook = serviceHook;
        this.loading = false;
      });
  }

  @Watch('serviceHook')
  onLibraryUpdated() {
    appState.setTitle(`Service Hook: ${this.serviceHook?.options.uri}`);
  }

  back() {
    backOr({ name: 'service-hooks' });
  }

  save(model: ServiceHookUpdateModel) {
    if (!this.serviceHookId) {
      this.snackbarOptions = EcSnackBar.makeProgressOptions('Creating service hook ...');
      this.ServiceHookService.createServiceHook(model)
        .then((serviceHook) => {
          this.snackbarOptions.value = false;
          this.$nextTick(() => {
            this.snackbarOptions = EcSnackBar.makeSuccessfulOptions('Successfully created');
          });
          this.$router.replace({ name: 'service-hook', params: { id: serviceHook.id } });
        })
        .catch((http) => {
          this.snackbarOptions = EcSnackBar.makeDefaultOptions();
          this.apiErrors = http.response?.data?.errors || [];
        });

      return;
    }

    this.snackbarOptions = EcSnackBar.makeProgressOptions('Saving service hook ...');
    this.ServiceHookService.updateServiceHook(this.serviceHookId, model)
      .then((serviceHook) => {
        this.snackbarOptions.value = false;
        this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeSuccessfulOptions('Successfully updated'); });

        this.serviceHook = serviceHook;
      })
      .catch((http) => {
        this.snackbarOptions = EcSnackBar.makeDefaultOptions();
        this.apiErrors = http.response?.data?.errors || [];
      });
  }

  test(model: ServiceHookUpdateModel) {
    Object.assign(this.serviceHook, model);
    this.serviceHook.id = this.serviceHookId ?? '';
    this.serviceHookName = this.serviceHook.name;
    this.showTestDialog = true;
  }

  formValidationError() {
    this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions('Please correct any errors before saving or testing');
  }

  async deleteServiceHook() {
    if (!this.serviceHookId) return;

    try {
      const text = 'If you delete this service hook, screening results will no longer be sent to the endpoint.';
      appState.openDialog({
        title: 'Delete Service Hook?',
        text: `<p>Are you sure?</p><p>${text}</p>`,
        actions: [{
          name: 'Delete',
          color: 'warning',
          handler: async () => {
            this.snackbarOptions = EcSnackBar.makeProgressOptions('Deleting service hook ...');
            this.ServiceHookService.deleteServiceHook(this.serviceHookId ?? "")
              .then(() => {
                this.snackbarOptions.value = false;
                this.back();
              })
            .catch((error: Error) => {
              this.snackbarOptions.value = false;
              this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to delete service hook: ${error.message}`); });
            }
          )
        },
      },
      {
        name: 'Cancel',
        color: 'primary',
        handler: () => Promise.resolve(false),
      }],
    });
  } catch (error) {
      this.snackbarOptions.value = false;
      this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to delete service hook : ${error}`); });
    }
  }
}
